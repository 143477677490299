module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-theme-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":650,"linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"sizeByPixelDensity":false,"backgroundColor":"white","quality":50,"withWebp":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".mdx",".md"],"defaultLayouts":{"default":"/home/runner/work/kernel-v2/kernel-v2/src/modules/layouts/default_layout.js","blogPosts":"/home/runner/work/kernel-v2/kernel-v2/src/modules/layouts/blogPost_layout.js"},"remarkPlugins":[null],"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-embed-video","options":{"width":800,"ratio":1.77,"height":400,"related":false,"noIframeBorder":true,"showInfo":false}},{"resolve":"gatsby-remark-autolink-headers","options":{"isIconAfterHeader":true,"className":"anchor-link","icon":"<span>¶</span>"}},"gatsby-remark-responsive-iframe",{"resolve":"gatsby-remark-images","options":{"maxWidth":1000,"linkImagesToOriginal":false}},{"resolve":"gatsby-remark-code-titles","options":{"className":"prism-code-title"}}],"lessBabel":false,"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/home/runner/work/kernel-v2/kernel-v2","JSFrontmatterEngine":false,"engines":{}},
    },{
      plugin: require('../node_modules/gatsby-plugin-lunr/gatsby-browser.js'),
      options: {"plugins":[],"languages":[{"name":"en"}],"fields":[{"name":"title","store":true,"attributes":{"boost":20}},{"name":"keywords","attributes":{"boost":15}},{"name":"isBlog","store":true},{"name":"authors","store":true},{"name":"type","store":true},{"name":"description","store":true,"attributes":{"boost":15}},{"name":"date","store":true},{"name":"url","store":true},{"name":"excerpt","store":true,"attributes":{"boost":5}}],"resolvers":{"Mdx":{}},"filename":"search_index.json","fetchOptions":{"credentials":"same-origin"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Kernel Community","short_name":"Kernel","start_url":"/","background_color":"#291a42","theme_color":"#5AE2CA","display":"standalone","include_favicon":false,"icon":"src/modules/utility/icon-512x512.png","cache_busting_mode":"none","theme_color_in_head":false,"legacy":true,"crossOrigin":"anonymous","cacheDigest":null},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"workboxConfig":{"globPatterns":["**/images/icons/icon-512x512.png"]}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
