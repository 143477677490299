// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---blog-posts-en-blog-community-celo-space-mdx": () => import("./../../../blogPosts/en/blog/community/celo-space.mdx" /* webpackChunkName: "component---blog-posts-en-blog-community-celo-space-mdx" */),
  "component---blog-posts-en-blog-community-free-learn-mdx": () => import("./../../../blogPosts/en/blog/community/free-learn.mdx" /* webpackChunkName: "component---blog-posts-en-blog-community-free-learn-mdx" */),
  "component---blog-posts-en-blog-community-freedom-in-learning-mdx": () => import("./../../../blogPosts/en/blog/community/freedom-in-learning.mdx" /* webpackChunkName: "component---blog-posts-en-blog-community-freedom-in-learning-mdx" */),
  "component---blog-posts-en-blog-community-kernel-a-refreshed-definition-mdx": () => import("./../../../blogPosts/en/blog/community/kernel-a-refreshed-definition.mdx" /* webpackChunkName: "component---blog-posts-en-blog-community-kernel-a-refreshed-definition-mdx" */),
  "component---blog-posts-en-blog-community-number-one-identity-mdx": () => import("./../../../blogPosts/en/blog/community/number-one-identity.mdx" /* webpackChunkName: "component---blog-posts-en-blog-community-number-one-identity-mdx" */),
  "component---blog-posts-en-blog-community-scholarships-mdx": () => import("./../../../blogPosts/en/blog/community/scholarships.mdx" /* webpackChunkName: "component---blog-posts-en-blog-community-scholarships-mdx" */),
  "component---blog-posts-en-blog-community-setting-prison-art-free-mdx": () => import("./../../../blogPosts/en/blog/community/setting-prison-art-free.mdx" /* webpackChunkName: "component---blog-posts-en-blog-community-setting-prison-art-free-mdx" */),
  "component---blog-posts-en-blog-community-web-3-contracting-mdx": () => import("./../../../blogPosts/en/blog/community/web3-contracting.mdx" /* webpackChunkName: "component---blog-posts-en-blog-community-web-3-contracting-mdx" */),
  "component---blog-posts-en-blog-editorial-arranging-participation-mdx": () => import("./../../../blogPosts/en/blog/Editorial/arranging-participation.mdx" /* webpackChunkName: "component---blog-posts-en-blog-editorial-arranging-participation-mdx" */),
  "component---blog-posts-en-blog-editorial-constituting-consensus-mdx": () => import("./../../../blogPosts/en/blog/Editorial/constituting-consensus.mdx" /* webpackChunkName: "component---blog-posts-en-blog-editorial-constituting-consensus-mdx" */),
  "component---blog-posts-en-blog-editorial-kernel-returns-mdx": () => import("./../../../blogPosts/en/blog/Editorial/kernel-returns.mdx" /* webpackChunkName: "component---blog-posts-en-blog-editorial-kernel-returns-mdx" */),
  "component---blog-posts-en-blog-editorial-launching-kb-3-mdx": () => import("./../../../blogPosts/en/blog/Editorial/launching-kb3.mdx" /* webpackChunkName: "component---blog-posts-en-blog-editorial-launching-kb-3-mdx" */),
  "component---blog-posts-en-blog-editorial-signature-stories-mdx": () => import("./../../../blogPosts/en/blog/Editorial/signature-stories.mdx" /* webpackChunkName: "component---blog-posts-en-blog-editorial-signature-stories-mdx" */),
  "component---blog-posts-en-blog-editorial-summer-of-love-mdx": () => import("./../../../blogPosts/en/blog/Editorial/summer-of-love.mdx" /* webpackChunkName: "component---blog-posts-en-blog-editorial-summer-of-love-mdx" */),
  "component---blog-posts-en-blog-editorial-what-is-kernel-mdx": () => import("./../../../blogPosts/en/blog/Editorial/what-is-kernel.mdx" /* webpackChunkName: "component---blog-posts-en-blog-editorial-what-is-kernel-mdx" */),
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---content-cn-start-index-mdx": () => import("./../../../content/cn/start/index.mdx" /* webpackChunkName: "component---content-cn-start-index-mdx" */),
  "component---content-cn-start-principled-people-mdx": () => import("./../../../content/cn/start/principled-people.mdx" /* webpackChunkName: "component---content-cn-start-principled-people-mdx" */),
  "component---content-en-build-culture-index-mdx": () => import("./../../../content/en/build/culture/index.mdx" /* webpackChunkName: "component---content-en-build-culture-index-mdx" */),
  "component---content-en-build-dance-classes-mdx": () => import("./../../../content/en/build/dance/classes.mdx" /* webpackChunkName: "component---content-en-build-dance-classes-mdx" */),
  "component---content-en-build-dance-glossary-mdx": () => import("./../../../content/en/build/dance/glossary.mdx" /* webpackChunkName: "component---content-en-build-dance-glossary-mdx" */),
  "component---content-en-build-dance-index-mdx": () => import("./../../../content/en/build/dance/index.mdx" /* webpackChunkName: "component---content-en-build-dance-index-mdx" */),
  "component---content-en-build-dance-inspiration-mdx": () => import("./../../../content/en/build/dance/inspiration.mdx" /* webpackChunkName: "component---content-en-build-dance-inspiration-mdx" */),
  "component---content-en-build-daos-cosmic-reality-mdx": () => import("./../../../content/en/build/daos/cosmic-reality.mdx" /* webpackChunkName: "component---content-en-build-daos-cosmic-reality-mdx" */),
  "component---content-en-build-daos-expanding-universe-mdx": () => import("./../../../content/en/build/daos/expanding-universe.mdx" /* webpackChunkName: "component---content-en-build-daos-expanding-universe-mdx" */),
  "component---content-en-build-daos-index-mdx": () => import("./../../../content/en/build/daos/index.mdx" /* webpackChunkName: "component---content-en-build-daos-index-mdx" */),
  "component---content-en-build-daos-infinite-learning-mdx": () => import("./../../../content/en/build/daos/infinite-learning.mdx" /* webpackChunkName: "component---content-en-build-daos-infinite-learning-mdx" */),
  "component---content-en-build-daos-open-multiverse-mdx": () => import("./../../../content/en/build/daos/open-multiverse.mdx" /* webpackChunkName: "component---content-en-build-daos-open-multiverse-mdx" */),
  "component---content-en-build-daos-past-light-mdx": () => import("./../../../content/en/build/daos/past-light.mdx" /* webpackChunkName: "component---content-en-build-daos-past-light-mdx" */),
  "component---content-en-build-daos-record-mdx": () => import("./../../../content/en/build/daos/record.mdx" /* webpackChunkName: "component---content-en-build-daos-record-mdx" */),
  "component---content-en-build-decentralized-ai-analogue-imaginings-mdx": () => import("./../../../content/en/build/decentralized-ai/analogue-imaginings.mdx" /* webpackChunkName: "component---content-en-build-decentralized-ai-analogue-imaginings-mdx" */),
  "component---content-en-build-decentralized-ai-generative-adversaries-mdx": () => import("./../../../content/en/build/decentralized-ai/generative-adversaries.mdx" /* webpackChunkName: "component---content-en-build-decentralized-ai-generative-adversaries-mdx" */),
  "component---content-en-build-decentralized-ai-index-mdx": () => import("./../../../content/en/build/decentralized-ai/index.mdx" /* webpackChunkName: "component---content-en-build-decentralized-ai-index-mdx" */),
  "component---content-en-build-decentralized-ai-into-the-ocean-mdx": () => import("./../../../content/en/build/decentralized-ai/into-the-ocean.mdx" /* webpackChunkName: "component---content-en-build-decentralized-ai-into-the-ocean-mdx" */),
  "component---content-en-build-decentralized-ai-punks-python-mdx": () => import("./../../../content/en/build/decentralized-ai/punks-python.mdx" /* webpackChunkName: "component---content-en-build-decentralized-ai-punks-python-mdx" */),
  "component---content-en-build-defi-funding-grants-mdx": () => import("./../../../content/en/build/defi/funding-grants.mdx" /* webpackChunkName: "component---content-en-build-defi-funding-grants-mdx" */),
  "component---content-en-build-defi-index-mdx": () => import("./../../../content/en/build/defi/index.mdx" /* webpackChunkName: "component---content-en-build-defi-index-mdx" */),
  "component---content-en-build-desci-index-mdx": () => import("./../../../content/en/build/desci/index.mdx" /* webpackChunkName: "component---content-en-build-desci-index-mdx" */),
  "component---content-en-build-desci-open-questions-mdx": () => import("./../../../content/en/build/desci/open-questions.mdx" /* webpackChunkName: "component---content-en-build-desci-open-questions-mdx" */),
  "component---content-en-build-games-freedom-mdx": () => import("./../../../content/en/build/games/freedom.mdx" /* webpackChunkName: "component---content-en-build-games-freedom-mdx" */),
  "component---content-en-build-games-index-mdx": () => import("./../../../content/en/build/games/index.mdx" /* webpackChunkName: "component---content-en-build-games-index-mdx" */),
  "component---content-en-build-games-infinite-mdx": () => import("./../../../content/en/build/games/infinite.mdx" /* webpackChunkName: "component---content-en-build-games-infinite-mdx" */),
  "component---content-en-build-games-patterns-mdx": () => import("./../../../content/en/build/games/patterns.mdx" /* webpackChunkName: "component---content-en-build-games-patterns-mdx" */),
  "component---content-en-build-games-recordings-mdx": () => import("./../../../content/en/build/games/recordings.mdx" /* webpackChunkName: "component---content-en-build-games-recordings-mdx" */),
  "component---content-en-build-games-value-mdx": () => import("./../../../content/en/build/games/value.mdx" /* webpackChunkName: "component---content-en-build-games-value-mdx" */),
  "component---content-en-build-index-mdx": () => import("./../../../content/en/build/index.mdx" /* webpackChunkName: "component---content-en-build-index-mdx" */),
  "component---content-en-build-nfteasy-character-types-mdx": () => import("./../../../content/en/build/nfteasy/character-types.mdx" /* webpackChunkName: "component---content-en-build-nfteasy-character-types-mdx" */),
  "component---content-en-build-nfteasy-index-mdx": () => import("./../../../content/en/build/nfteasy/index.mdx" /* webpackChunkName: "component---content-en-build-nfteasy-index-mdx" */),
  "component---content-en-build-nfteasy-long-life-libraries-mdx": () => import("./../../../content/en/build/nfteasy/long-life-libraries.mdx" /* webpackChunkName: "component---content-en-build-nfteasy-long-life-libraries-mdx" */),
  "component---content-en-build-nfteasy-perma-network-culture-mdx": () => import("./../../../content/en/build/nfteasy/perma-network-culture.mdx" /* webpackChunkName: "component---content-en-build-nfteasy-perma-network-culture-mdx" */),
  "component---content-en-build-nfteasy-woulds-tries-mdx": () => import("./../../../content/en/build/nfteasy/woulds-tries.mdx" /* webpackChunkName: "component---content-en-build-nfteasy-woulds-tries-mdx" */),
  "component---content-en-build-open-data-index-mdx": () => import("./../../../content/en/build/open-data/index.mdx" /* webpackChunkName: "component---content-en-build-open-data-index-mdx" */),
  "component---content-en-build-open-data-trans-local-mdx": () => import("./../../../content/en/build/open-data/trans-local.mdx" /* webpackChunkName: "component---content-en-build-open-data-trans-local-mdx" */),
  "component---content-en-build-open-data-transact-mdx": () => import("./../../../content/en/build/open-data/transact.mdx" /* webpackChunkName: "component---content-en-build-open-data-transact-mdx" */),
  "component---content-en-build-open-data-transcend-mdx": () => import("./../../../content/en/build/open-data/transcend.mdx" /* webpackChunkName: "component---content-en-build-open-data-transcend-mdx" */),
  "component---content-en-build-open-data-transfinity-mdx": () => import("./../../../content/en/build/open-data/transfinity.mdx" /* webpackChunkName: "component---content-en-build-open-data-transfinity-mdx" */),
  "component---content-en-build-outsmarting-contracts-index-mdx": () => import("./../../../content/en/build/outsmarting-contracts/index.mdx" /* webpackChunkName: "component---content-en-build-outsmarting-contracts-index-mdx" */),
  "component---content-en-build-outsmarting-contracts-lifting-the-veil-mdx": () => import("./../../../content/en/build/outsmarting-contracts/lifting-the-veil.mdx" /* webpackChunkName: "component---content-en-build-outsmarting-contracts-lifting-the-veil-mdx" */),
  "component---content-en-build-outsmarting-contracts-random-order-mdx": () => import("./../../../content/en/build/outsmarting-contracts/random-order.mdx" /* webpackChunkName: "component---content-en-build-outsmarting-contracts-random-order-mdx" */),
  "component---content-en-build-outsmarting-contracts-reenter-the-kingdom-mdx": () => import("./../../../content/en/build/outsmarting-contracts/reenter-the-kingdom.mdx" /* webpackChunkName: "component---content-en-build-outsmarting-contracts-reenter-the-kingdom-mdx" */),
  "component---content-en-build-outsmarting-contracts-true-store-mdx": () => import("./../../../content/en/build/outsmarting-contracts/true-store.mdx" /* webpackChunkName: "component---content-en-build-outsmarting-contracts-true-store-mdx" */),
  "component---content-en-build-regeneration-index-mdx": () => import("./../../../content/en/build/regeneration/index.mdx" /* webpackChunkName: "component---content-en-build-regeneration-index-mdx" */),
  "component---content-en-build-regeneration-locus-of-control-mdx": () => import("./../../../content/en/build/regeneration/locus-of-control.mdx" /* webpackChunkName: "component---content-en-build-regeneration-locus-of-control-mdx" */),
  "component---content-en-build-regeneration-phoenix-mdx": () => import("./../../../content/en/build/regeneration/phoenix.mdx" /* webpackChunkName: "component---content-en-build-regeneration-phoenix-mdx" */),
  "component---content-en-build-regeneration-points-of-leverage-mdx": () => import("./../../../content/en/build/regeneration/points-of-leverage.mdx" /* webpackChunkName: "component---content-en-build-regeneration-points-of-leverage-mdx" */),
  "component---content-en-build-regeneration-principled-patterning-mdx": () => import("./../../../content/en/build/regeneration/principled-patterning.mdx" /* webpackChunkName: "component---content-en-build-regeneration-principled-patterning-mdx" */),
  "component---content-en-build-sati-citta-mdx": () => import("./../../../content/en/build/sati/citta.mdx" /* webpackChunkName: "component---content-en-build-sati-citta-mdx" */),
  "component---content-en-build-sati-dhamma-mdx": () => import("./../../../content/en/build/sati/dhamma.mdx" /* webpackChunkName: "component---content-en-build-sati-dhamma-mdx" */),
  "component---content-en-build-sati-index-mdx": () => import("./../../../content/en/build/sati/index.mdx" /* webpackChunkName: "component---content-en-build-sati-index-mdx" */),
  "component---content-en-build-sati-kayagata-mdx": () => import("./../../../content/en/build/sati/kayagata.mdx" /* webpackChunkName: "component---content-en-build-sati-kayagata-mdx" */),
  "component---content-en-build-sati-vedana-mdx": () => import("./../../../content/en/build/sati/vedana.mdx" /* webpackChunkName: "component---content-en-build-sati-vedana-mdx" */),
  "component---content-en-conversation-hospitality-deep-response-mdx": () => import("./../../../content/en/conversation/hospitality/deep-response.mdx" /* webpackChunkName: "component---content-en-conversation-hospitality-deep-response-mdx" */),
  "component---content-en-conversation-hospitality-index-mdx": () => import("./../../../content/en/conversation/hospitality/index.mdx" /* webpackChunkName: "component---content-en-conversation-hospitality-index-mdx" */),
  "component---content-en-conversation-in-visibility-mdx": () => import("./../../../content/en/conversation/in-visibility.mdx" /* webpackChunkName: "component---content-en-conversation-in-visibility-mdx" */),
  "component---content-en-conversation-index-mdx": () => import("./../../../content/en/conversation/index.mdx" /* webpackChunkName: "component---content-en-conversation-index-mdx" */),
  "component---content-en-conversation-oral-testimony-mdx": () => import("./../../../content/en/conversation/oral-testimony.mdx" /* webpackChunkName: "component---content-en-conversation-oral-testimony-mdx" */),
  "component---content-en-conversation-power-less-mdx": () => import("./../../../content/en/conversation/power-less.mdx" /* webpackChunkName: "component---content-en-conversation-power-less-mdx" */),
  "component---content-en-conversation-reciprocity-mdx": () => import("./../../../content/en/conversation/reciprocity.mdx" /* webpackChunkName: "component---content-en-conversation-reciprocity-mdx" */),
  "component---content-en-conversation-sand-talk-mdx": () => import("./../../../content/en/conversation/sand-talk.mdx" /* webpackChunkName: "component---content-en-conversation-sand-talk-mdx" */),
  "component---content-en-conversation-thanksgiving-mdx": () => import("./../../../content/en/conversation/thanksgiving.mdx" /* webpackChunkName: "component---content-en-conversation-thanksgiving-mdx" */),
  "component---content-en-conversation-true-voice-mdx": () => import("./../../../content/en/conversation/true-voice.mdx" /* webpackChunkName: "component---content-en-conversation-true-voice-mdx" */),
  "component---content-en-guiding-engineers-index-mdx": () => import("./../../../content/en/guiding/engineers/index.mdx" /* webpackChunkName: "component---content-en-guiding-engineers-index-mdx" */),
  "component---content-en-guiding-engineers-navigation-mdx": () => import("./../../../content/en/guiding/engineers/navigation.mdx" /* webpackChunkName: "component---content-en-guiding-engineers-navigation-mdx" */),
  "component---content-en-guiding-engineers-search-mdx": () => import("./../../../content/en/guiding/engineers/search.mdx" /* webpackChunkName: "component---content-en-guiding-engineers-search-mdx" */),
  "component---content-en-guiding-engineers-translation-mdx": () => import("./../../../content/en/guiding/engineers/translation.mdx" /* webpackChunkName: "component---content-en-guiding-engineers-translation-mdx" */),
  "component---content-en-guiding-guides-index-mdx": () => import("./../../../content/en/guiding/guides/index.mdx" /* webpackChunkName: "component---content-en-guiding-guides-index-mdx" */),
  "component---content-en-guiding-guides-interviews-mdx": () => import("./../../../content/en/guiding/guides/interviews.mdx" /* webpackChunkName: "component---content-en-guiding-guides-interviews-mdx" */),
  "component---content-en-guiding-guides-juntos-mdx": () => import("./../../../content/en/guiding/guides/juntos.mdx" /* webpackChunkName: "component---content-en-guiding-guides-juntos-mdx" */),
  "component---content-en-guiding-guides-mentors-mdx": () => import("./../../../content/en/guiding/guides/mentors.mdx" /* webpackChunkName: "component---content-en-guiding-guides-mentors-mdx" */),
  "component---content-en-guiding-index-mdx": () => import("./../../../content/en/guiding/index.mdx" /* webpackChunkName: "component---content-en-guiding-index-mdx" */),
  "component---content-en-guiding-privacy-mdx": () => import("./../../../content/en/guiding/privacy.mdx" /* webpackChunkName: "component---content-en-guiding-privacy-mdx" */),
  "component---content-en-guiding-terms-of-service-mdx": () => import("./../../../content/en/guiding/terms-of-service.mdx" /* webpackChunkName: "component---content-en-guiding-terms-of-service-mdx" */),
  "component---content-en-guiding-writers-frontmatter-mdx": () => import("./../../../content/en/guiding/writers/frontmatter.mdx" /* webpackChunkName: "component---content-en-guiding-writers-frontmatter-mdx" */),
  "component---content-en-guiding-writers-how-to-mdx": () => import("./../../../content/en/guiding/writers/how-to.mdx" /* webpackChunkName: "component---content-en-guiding-writers-how-to-mdx" */),
  "component---content-en-guiding-writers-index-mdx": () => import("./../../../content/en/guiding/writers/index.mdx" /* webpackChunkName: "component---content-en-guiding-writers-index-mdx" */),
  "component---content-en-guiding-writers-recipes-components-mdx": () => import("./../../../content/en/guiding/writers/recipes/components.mdx" /* webpackChunkName: "component---content-en-guiding-writers-recipes-components-mdx" */),
  "component---content-en-guiding-writers-recipes-icons-mdx": () => import("./../../../content/en/guiding/writers/recipes/icons.mdx" /* webpackChunkName: "component---content-en-guiding-writers-recipes-icons-mdx" */),
  "component---content-en-guiding-writers-recipes-index-mdx": () => import("./../../../content/en/guiding/writers/recipes/index.mdx" /* webpackChunkName: "component---content-en-guiding-writers-recipes-index-mdx" */),
  "component---content-en-guiding-writers-translation-mdx": () => import("./../../../content/en/guiding/writers/translation.mdx" /* webpackChunkName: "component---content-en-guiding-writers-translation-mdx" */),
  "component---content-en-index-mdx": () => import("./../../../content/en/index.mdx" /* webpackChunkName: "component---content-en-index-mdx" */),
  "component---content-en-koans-index-mdx": () => import("./../../../content/en/koans/index.mdx" /* webpackChunkName: "component---content-en-koans-index-mdx" */),
  "component---content-en-koans-module-1-mdx": () => import("./../../../content/en/koans/module-1.mdx" /* webpackChunkName: "component---content-en-koans-module-1-mdx" */),
  "component---content-en-koans-module-3-mdx": () => import("./../../../content/en/koans/module-3.mdx" /* webpackChunkName: "component---content-en-koans-module-3-mdx" */),
  "component---content-en-koans-module-7-mdx": () => import("./../../../content/en/koans/module-7.mdx" /* webpackChunkName: "component---content-en-koans-module-7-mdx" */),
  "component---content-en-learn-index-mdx": () => import("./../../../content/en/learn/index.mdx" /* webpackChunkName: "component---content-en-learn-index-mdx" */),
  "component---content-en-learn-module-0-conversation-mdx": () => import("./../../../content/en/learn/module-0/conversation.mdx" /* webpackChunkName: "component---content-en-learn-module-0-conversation-mdx" */),
  "component---content-en-learn-module-0-index-mdx": () => import("./../../../content/en/learn/module-0/index.mdx" /* webpackChunkName: "component---content-en-learn-module-0-index-mdx" */),
  "component---content-en-learn-module-0-money-language-mdx": () => import("./../../../content/en/learn/module-0/money-language.mdx" /* webpackChunkName: "component---content-en-learn-module-0-money-language-mdx" */),
  "component---content-en-learn-module-0-play-of-pattern-mdx": () => import("./../../../content/en/learn/module-0/play-of-pattern.mdx" /* webpackChunkName: "component---content-en-learn-module-0-play-of-pattern-mdx" */),
  "component---content-en-learn-module-0-purpose-mdx": () => import("./../../../content/en/learn/module-0/purpose.mdx" /* webpackChunkName: "component---content-en-learn-module-0-purpose-mdx" */),
  "component---content-en-learn-module-0-trust-mdx": () => import("./../../../content/en/learn/module-0/trust.mdx" /* webpackChunkName: "component---content-en-learn-module-0-trust-mdx" */),
  "component---content-en-learn-module-1-dreamers-mdx": () => import("./../../../content/en/learn/module-1/dreamers.mdx" /* webpackChunkName: "component---content-en-learn-module-1-dreamers-mdx" */),
  "component---content-en-learn-module-1-index-mdx": () => import("./../../../content/en/learn/module-1/index.mdx" /* webpackChunkName: "component---content-en-learn-module-1-index-mdx" */),
  "component---content-en-learn-module-1-joyful-subversion-mdx": () => import("./../../../content/en/learn/module-1/joyful-subversion.mdx" /* webpackChunkName: "component---content-en-learn-module-1-joyful-subversion-mdx" */),
  "component---content-en-learn-module-1-meaning-mdx": () => import("./../../../content/en/learn/module-1/meaning.mdx" /* webpackChunkName: "component---content-en-learn-module-1-meaning-mdx" */),
  "component---content-en-learn-module-1-playdough-protocols-mdx": () => import("./../../../content/en/learn/module-1/playdough-protocols.mdx" /* webpackChunkName: "component---content-en-learn-module-1-playdough-protocols-mdx" */),
  "component---content-en-learn-module-1-understanding-ethereum-mdx": () => import("./../../../content/en/learn/module-1/understanding-ethereum.mdx" /* webpackChunkName: "component---content-en-learn-module-1-understanding-ethereum-mdx" */),
  "component---content-en-learn-module-1-value-mdx": () => import("./../../../content/en/learn/module-1/value.mdx" /* webpackChunkName: "component---content-en-learn-module-1-value-mdx" */),
  "component---content-en-learn-module-2-banking-mdx": () => import("./../../../content/en/learn/module-2/banking.mdx" /* webpackChunkName: "component---content-en-learn-module-2-banking-mdx" */),
  "component---content-en-learn-module-2-better-questions-mdx": () => import("./../../../content/en/learn/module-2/better-questions.mdx" /* webpackChunkName: "component---content-en-learn-module-2-better-questions-mdx" */),
  "component---content-en-learn-module-2-debt-mdx": () => import("./../../../content/en/learn/module-2/debt.mdx" /* webpackChunkName: "component---content-en-learn-module-2-debt-mdx" */),
  "component---content-en-learn-module-2-engineering-mdx": () => import("./../../../content/en/learn/module-2/engineering.mdx" /* webpackChunkName: "component---content-en-learn-module-2-engineering-mdx" */),
  "component---content-en-learn-module-2-index-mdx": () => import("./../../../content/en/learn/module-2/index.mdx" /* webpackChunkName: "component---content-en-learn-module-2-index-mdx" */),
  "component---content-en-learn-module-2-shelling-out-mdx": () => import("./../../../content/en/learn/module-2/shelling-out.mdx" /* webpackChunkName: "component---content-en-learn-module-2-shelling-out-mdx" */),
  "component---content-en-learn-module-3-freedom-mdx": () => import("./../../../content/en/learn/module-3/freedom.mdx" /* webpackChunkName: "component---content-en-learn-module-3-freedom-mdx" */),
  "component---content-en-learn-module-3-humility-mdx": () => import("./../../../content/en/learn/module-3/humility.mdx" /* webpackChunkName: "component---content-en-learn-module-3-humility-mdx" */),
  "component---content-en-learn-module-3-index-mdx": () => import("./../../../content/en/learn/module-3/index.mdx" /* webpackChunkName: "component---content-en-learn-module-3-index-mdx" */),
  "component---content-en-learn-module-3-intention-mdx": () => import("./../../../content/en/learn/module-3/intention.mdx" /* webpackChunkName: "component---content-en-learn-module-3-intention-mdx" */),
  "component---content-en-learn-module-3-lock-it-open-mdx": () => import("./../../../content/en/learn/module-3/lock-it-open.mdx" /* webpackChunkName: "component---content-en-learn-module-3-lock-it-open-mdx" */),
  "component---content-en-learn-module-3-remember-mdx": () => import("./../../../content/en/learn/module-3/remember.mdx" /* webpackChunkName: "component---content-en-learn-module-3-remember-mdx" */),
  "component---content-en-learn-module-3-time-mdx": () => import("./../../../content/en/learn/module-3/time.mdx" /* webpackChunkName: "component---content-en-learn-module-3-time-mdx" */),
  "component---content-en-learn-module-4-art-mdx": () => import("./../../../content/en/learn/module-4/art.mdx" /* webpackChunkName: "component---content-en-learn-module-4-art-mdx" */),
  "component---content-en-learn-module-4-consensus-mdx": () => import("./../../../content/en/learn/module-4/consensus.mdx" /* webpackChunkName: "component---content-en-learn-module-4-consensus-mdx" */),
  "component---content-en-learn-module-4-governance-mdx": () => import("./../../../content/en/learn/module-4/governance.mdx" /* webpackChunkName: "component---content-en-learn-module-4-governance-mdx" */),
  "component---content-en-learn-module-4-index-mdx": () => import("./../../../content/en/learn/module-4/index.mdx" /* webpackChunkName: "component---content-en-learn-module-4-index-mdx" */),
  "component---content-en-learn-module-4-liberal-radical-mdx": () => import("./../../../content/en/learn/module-4/liberal-radical.mdx" /* webpackChunkName: "component---content-en-learn-module-4-liberal-radical-mdx" */),
  "component---content-en-learn-module-4-self-enquiry-mdx": () => import("./../../../content/en/learn/module-4/self-enquiry.mdx" /* webpackChunkName: "component---content-en-learn-module-4-self-enquiry-mdx" */),
  "component---content-en-learn-module-4-the-garden-mdx": () => import("./../../../content/en/learn/module-4/the-garden.mdx" /* webpackChunkName: "component---content-en-learn-module-4-the-garden-mdx" */),
  "component---content-en-learn-module-5-amazon-unbounded-search-mdx": () => import("./../../../content/en/learn/module-5/amazon-unbounded-search.mdx" /* webpackChunkName: "component---content-en-learn-module-5-amazon-unbounded-search-mdx" */),
  "component---content-en-learn-module-5-incentives-mdx": () => import("./../../../content/en/learn/module-5/incentives.mdx" /* webpackChunkName: "component---content-en-learn-module-5-incentives-mdx" */),
  "component---content-en-learn-module-5-index-mdx": () => import("./../../../content/en/learn/module-5/index.mdx" /* webpackChunkName: "component---content-en-learn-module-5-index-mdx" */),
  "component---content-en-learn-module-5-listening-stories-mdx": () => import("./../../../content/en/learn/module-5/listening-stories.mdx" /* webpackChunkName: "component---content-en-learn-module-5-listening-stories-mdx" */),
  "component---content-en-learn-module-5-prosocial-value-mdx": () => import("./../../../content/en/learn/module-5/prosocial-value.mdx" /* webpackChunkName: "component---content-en-learn-module-5-prosocial-value-mdx" */),
  "component---content-en-learn-module-5-reveal-the-universe-mdx": () => import("./../../../content/en/learn/module-5/reveal-the-universe.mdx" /* webpackChunkName: "component---content-en-learn-module-5-reveal-the-universe-mdx" */),
  "component---content-en-learn-module-5-the-peoples-narrative-mdx": () => import("./../../../content/en/learn/module-5/the-peoples-narrative.mdx" /* webpackChunkName: "component---content-en-learn-module-5-the-peoples-narrative-mdx" */),
  "component---content-en-learn-module-6-censorship-resistance-mdx": () => import("./../../../content/en/learn/module-6/censorship-resistance.mdx" /* webpackChunkName: "component---content-en-learn-module-6-censorship-resistance-mdx" */),
  "component---content-en-learn-module-6-duende-mdx": () => import("./../../../content/en/learn/module-6/duende.mdx" /* webpackChunkName: "component---content-en-learn-module-6-duende-mdx" */),
  "component---content-en-learn-module-6-index-mdx": () => import("./../../../content/en/learn/module-6/index.mdx" /* webpackChunkName: "component---content-en-learn-module-6-index-mdx" */),
  "component---content-en-learn-module-6-inventing-on-principle-mdx": () => import("./../../../content/en/learn/module-6/inventing-on-principle.mdx" /* webpackChunkName: "component---content-en-learn-module-6-inventing-on-principle-mdx" */),
  "component---content-en-learn-module-6-learn-mdx": () => import("./../../../content/en/learn/module-6/learn.mdx" /* webpackChunkName: "component---content-en-learn-module-6-learn-mdx" */),
  "component---content-en-learn-module-6-serenity-mdx": () => import("./../../../content/en/learn/module-6/serenity.mdx" /* webpackChunkName: "component---content-en-learn-module-6-serenity-mdx" */),
  "component---content-en-learn-module-7-giving-mdx": () => import("./../../../content/en/learn/module-7/giving.mdx" /* webpackChunkName: "component---content-en-learn-module-7-giving-mdx" */),
  "component---content-en-learn-module-7-index-mdx": () => import("./../../../content/en/learn/module-7/index.mdx" /* webpackChunkName: "component---content-en-learn-module-7-index-mdx" */),
  "component---content-en-learn-module-7-no-paradigm-mdx": () => import("./../../../content/en/learn/module-7/no-paradigm.mdx" /* webpackChunkName: "component---content-en-learn-module-7-no-paradigm-mdx" */),
  "component---content-en-learn-module-7-perfection-mdx": () => import("./../../../content/en/learn/module-7/perfection.mdx" /* webpackChunkName: "component---content-en-learn-module-7-perfection-mdx" */),
  "component---content-en-learn-module-7-scale-ability-mdx": () => import("./../../../content/en/learn/module-7/scale-ability.mdx" /* webpackChunkName: "component---content-en-learn-module-7-scale-ability-mdx" */),
  "component---content-en-learn-module-7-the-gift-mdx": () => import("./../../../content/en/learn/module-7/the-gift.mdx" /* webpackChunkName: "component---content-en-learn-module-7-the-gift-mdx" */),
  "component---content-en-learn-module-metta-beautiful-homes-mdx": () => import("./../../../content/en/learn/module-metta/beautiful-homes.mdx" /* webpackChunkName: "component---content-en-learn-module-metta-beautiful-homes-mdx" */),
  "component---content-en-learn-module-metta-build-native-altars-mdx": () => import("./../../../content/en/learn/module-metta/build-native-altars.mdx" /* webpackChunkName: "component---content-en-learn-module-metta-build-native-altars-mdx" */),
  "component---content-en-learn-module-metta-index-mdx": () => import("./../../../content/en/learn/module-metta/index.mdx" /* webpackChunkName: "component---content-en-learn-module-metta-index-mdx" */),
  "component---content-en-learn-module-metta-relating-kernel-mdx": () => import("./../../../content/en/learn/module-metta/relating-kernel.mdx" /* webpackChunkName: "component---content-en-learn-module-metta-relating-kernel-mdx" */),
  "component---content-en-learn-module-metta-school-sucks-mdx": () => import("./../../../content/en/learn/module-metta/school-sucks.mdx" /* webpackChunkName: "component---content-en-learn-module-metta-school-sucks-mdx" */),
  "component---content-en-learn-module-metta-start-small-mdx": () => import("./../../../content/en/learn/module-metta/start-small.mdx" /* webpackChunkName: "component---content-en-learn-module-metta-start-small-mdx" */),
  "component---content-en-resources-culture-mdx": () => import("./../../../content/en/resources/culture.mdx" /* webpackChunkName: "component---content-en-resources-culture-mdx" */),
  "component---content-en-resources-design-ux-mdx": () => import("./../../../content/en/resources/design-ux.mdx" /* webpackChunkName: "component---content-en-resources-design-ux-mdx" */),
  "component---content-en-resources-development-mdx": () => import("./../../../content/en/resources/development.mdx" /* webpackChunkName: "component---content-en-resources-development-mdx" */),
  "component---content-en-resources-index-mdx": () => import("./../../../content/en/resources/index.mdx" /* webpackChunkName: "component---content-en-resources-index-mdx" */),
  "component---content-en-resources-research-mdx": () => import("./../../../content/en/resources/research.mdx" /* webpackChunkName: "component---content-en-resources-research-mdx" */),
  "component---content-en-resources-security-mdx": () => import("./../../../content/en/resources/security.mdx" /* webpackChunkName: "component---content-en-resources-security-mdx" */),
  "component---content-en-start-code-of-conduct-mdx": () => import("./../../../content/en/start/code-of-conduct.mdx" /* webpackChunkName: "component---content-en-start-code-of-conduct-mdx" */),
  "component---content-en-start-faq-mdx": () => import("./../../../content/en/start/faq.mdx" /* webpackChunkName: "component---content-en-start-faq-mdx" */),
  "component---content-en-start-free-learning-mdx": () => import("./../../../content/en/start/free-learning.mdx" /* webpackChunkName: "component---content-en-start-free-learning-mdx" */),
  "component---content-en-start-how-to-use-mdx": () => import("./../../../content/en/start/how-to-use.mdx" /* webpackChunkName: "component---content-en-start-how-to-use-mdx" */),
  "component---content-en-start-index-mdx": () => import("./../../../content/en/start/index.mdx" /* webpackChunkName: "component---content-en-start-index-mdx" */),
  "component---content-en-start-principled-people-mdx": () => import("./../../../content/en/start/principled-people.mdx" /* webpackChunkName: "component---content-en-start-principled-people-mdx" */),
  "component---content-en-tokens-index-mdx": () => import("./../../../content/en/tokens/index.mdx" /* webpackChunkName: "component---content-en-tokens-index-mdx" */),
  "component---content-en-tokens-token-studies-dap-ps-mdx": () => import("./../../../content/en/tokens/token-studies/dap-ps.mdx" /* webpackChunkName: "component---content-en-tokens-token-studies-dap-ps-mdx" */),
  "component---content-en-tokens-token-studies-free-learn-index-mdx": () => import("./../../../content/en/tokens/token-studies/free-learn/index.mdx" /* webpackChunkName: "component---content-en-tokens-token-studies-free-learn-index-mdx" */),
  "component---content-en-tokens-token-studies-free-learn-learnings-mdx": () => import("./../../../content/en/tokens/token-studies/free-learn/learnings.mdx" /* webpackChunkName: "component---content-en-tokens-token-studies-free-learn-learnings-mdx" */),
  "component---content-en-tokens-token-studies-free-learn-og-paper-mdx": () => import("./../../../content/en/tokens/token-studies/free-learn/og-paper.mdx" /* webpackChunkName: "component---content-en-tokens-token-studies-free-learn-og-paper-mdx" */),
  "component---content-en-tokens-token-studies-free-learn-spec-mdx": () => import("./../../../content/en/tokens/token-studies/free-learn/spec.mdx" /* webpackChunkName: "component---content-en-tokens-token-studies-free-learn-spec-mdx" */),
  "component---content-en-tokens-token-studies-honour-mdx": () => import("./../../../content/en/tokens/token-studies/honour.mdx" /* webpackChunkName: "component---content-en-tokens-token-studies-honour-mdx" */),
  "component---content-en-tokens-token-studies-index-mdx": () => import("./../../../content/en/tokens/token-studies/index.mdx" /* webpackChunkName: "component---content-en-tokens-token-studies-index-mdx" */),
  "component---content-en-tokens-token-studies-maker-difference-mdx": () => import("./../../../content/en/tokens/token-studies/maker-difference.mdx" /* webpackChunkName: "component---content-en-tokens-token-studies-maker-difference-mdx" */),
  "component---content-en-tokens-token-studies-pan-mdx": () => import("./../../../content/en/tokens/token-studies/pan.mdx" /* webpackChunkName: "component---content-en-tokens-token-studies-pan-mdx" */),
  "component---content-en-tokens-token-studies-rai-mdx": () => import("./../../../content/en/tokens/token-studies/rai.mdx" /* webpackChunkName: "component---content-en-tokens-token-studies-rai-mdx" */),
  "component---content-en-tokens-token-studies-restoring-justice-mdx": () => import("./../../../content/en/tokens/token-studies/restoring-justice.mdx" /* webpackChunkName: "component---content-en-tokens-token-studies-restoring-justice-mdx" */),
  "component---content-en-tokens-token-studies-rocket-pool-mdx": () => import("./../../../content/en/tokens/token-studies/rocket-pool.mdx" /* webpackChunkName: "component---content-en-tokens-token-studies-rocket-pool-mdx" */),
  "component---content-en-tokens-token-studies-signature-economies-mdx": () => import("./../../../content/en/tokens/token-studies/signature-economies.mdx" /* webpackChunkName: "component---content-en-tokens-token-studies-signature-economies-mdx" */),
  "component---content-en-tokens-tokenomics-adaptive-sharing-mdx": () => import("./../../../content/en/tokens/tokenomics/adaptive-sharing.mdx" /* webpackChunkName: "component---content-en-tokens-tokenomics-adaptive-sharing-mdx" */),
  "component---content-en-tokens-tokenomics-communal-treasure-mdx": () => import("./../../../content/en/tokens/tokenomics/communal-treasure.mdx" /* webpackChunkName: "component---content-en-tokens-tokenomics-communal-treasure-mdx" */),
  "component---content-en-tokens-tokenomics-flying-high-mdx": () => import("./../../../content/en/tokens/tokenomics/flying-high.mdx" /* webpackChunkName: "component---content-en-tokens-tokenomics-flying-high-mdx" */),
  "component---content-en-tokens-tokenomics-index-mdx": () => import("./../../../content/en/tokens/tokenomics/index.mdx" /* webpackChunkName: "component---content-en-tokens-tokenomics-index-mdx" */),
  "component---content-en-tokens-tokenomics-math-mdx": () => import("./../../../content/en/tokens/tokenomics/math.mdx" /* webpackChunkName: "component---content-en-tokens-tokenomics-math-mdx" */),
  "component---content-en-tokens-tokenomics-mounting-up-mdx": () => import("./../../../content/en/tokens/tokenomics/mounting-up.mdx" /* webpackChunkName: "component---content-en-tokens-tokenomics-mounting-up-mdx" */),
  "component---content-en-tokens-tokenomics-nouns-mdx": () => import("./../../../content/en/tokens/tokenomics/nouns.mdx" /* webpackChunkName: "component---content-en-tokens-tokenomics-nouns-mdx" */),
  "component---content-en-tokens-tokenomics-ocean-curves-mdx": () => import("./../../../content/en/tokens/tokenomics/ocean-curves.mdx" /* webpackChunkName: "component---content-en-tokens-tokenomics-ocean-curves-mdx" */),
  "component---content-en-tokens-tokenomics-regenerative-commons-mdx": () => import("./../../../content/en/tokens/tokenomics/regenerative-commons.mdx" /* webpackChunkName: "component---content-en-tokens-tokenomics-regenerative-commons-mdx" */),
  "component---content-en-tokens-tokenomics-shared-wisdom-mdx": () => import("./../../../content/en/tokens/tokenomics/shared-wisdom.mdx" /* webpackChunkName: "component---content-en-tokens-tokenomics-shared-wisdom-mdx" */),
  "component---content-en-track-gaming-index-mdx": () => import("./../../../content/en/track-gaming/index.mdx" /* webpackChunkName: "component---content-en-track-gaming-index-mdx" */),
  "component---content-en-track-gaming-module-0-crafted-mdx": () => import("./../../../content/en/track-gaming/module-0/crafted.mdx" /* webpackChunkName: "component---content-en-track-gaming-module-0-crafted-mdx" */),
  "component---content-en-track-gaming-module-0-curated-mdx": () => import("./../../../content/en/track-gaming/module-0/curated.mdx" /* webpackChunkName: "component---content-en-track-gaming-module-0-curated-mdx" */),
  "component---content-en-track-gaming-module-0-index-mdx": () => import("./../../../content/en/track-gaming/module-0/index.mdx" /* webpackChunkName: "component---content-en-track-gaming-module-0-index-mdx" */),
  "component---content-en-track-gaming-module-1-crafted-mdx": () => import("./../../../content/en/track-gaming/module-1/crafted.mdx" /* webpackChunkName: "component---content-en-track-gaming-module-1-crafted-mdx" */),
  "component---content-en-track-gaming-module-1-curated-mdx": () => import("./../../../content/en/track-gaming/module-1/curated.mdx" /* webpackChunkName: "component---content-en-track-gaming-module-1-curated-mdx" */),
  "component---content-en-track-gaming-module-1-index-mdx": () => import("./../../../content/en/track-gaming/module-1/index.mdx" /* webpackChunkName: "component---content-en-track-gaming-module-1-index-mdx" */),
  "component---content-en-track-gaming-module-2-crafted-mdx": () => import("./../../../content/en/track-gaming/module-2/crafted.mdx" /* webpackChunkName: "component---content-en-track-gaming-module-2-crafted-mdx" */),
  "component---content-en-track-gaming-module-2-curated-mdx": () => import("./../../../content/en/track-gaming/module-2/curated.mdx" /* webpackChunkName: "component---content-en-track-gaming-module-2-curated-mdx" */),
  "component---content-en-track-gaming-module-2-index-mdx": () => import("./../../../content/en/track-gaming/module-2/index.mdx" /* webpackChunkName: "component---content-en-track-gaming-module-2-index-mdx" */),
  "component---content-en-track-gaming-module-3-crafted-mdx": () => import("./../../../content/en/track-gaming/module-3/crafted.mdx" /* webpackChunkName: "component---content-en-track-gaming-module-3-crafted-mdx" */),
  "component---content-en-track-gaming-module-3-curated-mdx": () => import("./../../../content/en/track-gaming/module-3/curated.mdx" /* webpackChunkName: "component---content-en-track-gaming-module-3-curated-mdx" */),
  "component---content-en-track-gaming-module-3-index-mdx": () => import("./../../../content/en/track-gaming/module-3/index.mdx" /* webpackChunkName: "component---content-en-track-gaming-module-3-index-mdx" */),
  "component---content-en-track-gaming-module-4-crafted-mdx": () => import("./../../../content/en/track-gaming/module-4/crafted.mdx" /* webpackChunkName: "component---content-en-track-gaming-module-4-crafted-mdx" */),
  "component---content-en-track-gaming-module-4-curated-mdx": () => import("./../../../content/en/track-gaming/module-4/curated.mdx" /* webpackChunkName: "component---content-en-track-gaming-module-4-curated-mdx" */),
  "component---content-en-track-gaming-module-4-index-mdx": () => import("./../../../content/en/track-gaming/module-4/index.mdx" /* webpackChunkName: "component---content-en-track-gaming-module-4-index-mdx" */),
  "component---content-en-track-gaming-module-5-crafted-mdx": () => import("./../../../content/en/track-gaming/module-5/crafted.mdx" /* webpackChunkName: "component---content-en-track-gaming-module-5-crafted-mdx" */),
  "component---content-en-track-gaming-module-5-curated-mdx": () => import("./../../../content/en/track-gaming/module-5/curated.mdx" /* webpackChunkName: "component---content-en-track-gaming-module-5-curated-mdx" */),
  "component---content-en-track-gaming-module-5-index-mdx": () => import("./../../../content/en/track-gaming/module-5/index.mdx" /* webpackChunkName: "component---content-en-track-gaming-module-5-index-mdx" */),
  "component---content-en-track-gaming-module-6-crafted-mdx": () => import("./../../../content/en/track-gaming/module-6/crafted.mdx" /* webpackChunkName: "component---content-en-track-gaming-module-6-crafted-mdx" */),
  "component---content-en-track-gaming-module-6-curated-mdx": () => import("./../../../content/en/track-gaming/module-6/curated.mdx" /* webpackChunkName: "component---content-en-track-gaming-module-6-curated-mdx" */),
  "component---content-en-track-gaming-module-6-index-mdx": () => import("./../../../content/en/track-gaming/module-6/index.mdx" /* webpackChunkName: "component---content-en-track-gaming-module-6-index-mdx" */),
  "component---content-en-track-gaming-x-genesis-collected-mdx": () => import("./../../../content/en/track-gaming/x-genesis/collected.mdx" /* webpackChunkName: "component---content-en-track-gaming-x-genesis-collected-mdx" */),
  "component---content-en-track-gaming-x-genesis-index-mdx": () => import("./../../../content/en/track-gaming/x-genesis/index.mdx" /* webpackChunkName: "component---content-en-track-gaming-x-genesis-index-mdx" */),
  "component---content-es-build-outsmarting-contracts-index-mdx": () => import("./../../../content/es/build/outsmarting-contracts/index.mdx" /* webpackChunkName: "component---content-es-build-outsmarting-contracts-index-mdx" */),
  "component---content-es-build-outsmarting-contracts-lifting-the-veil-mdx": () => import("./../../../content/es/build/outsmarting-contracts/lifting-the-veil.mdx" /* webpackChunkName: "component---content-es-build-outsmarting-contracts-lifting-the-veil-mdx" */),
  "component---content-es-build-outsmarting-contracts-random-order-mdx": () => import("./../../../content/es/build/outsmarting-contracts/random-order.mdx" /* webpackChunkName: "component---content-es-build-outsmarting-contracts-random-order-mdx" */),
  "component---content-es-build-outsmarting-contracts-reenter-the-kingdom-mdx": () => import("./../../../content/es/build/outsmarting-contracts/reenter-the-kingdom.mdx" /* webpackChunkName: "component---content-es-build-outsmarting-contracts-reenter-the-kingdom-mdx" */),
  "component---content-es-build-outsmarting-contracts-true-store-mdx": () => import("./../../../content/es/build/outsmarting-contracts/true-store.mdx" /* webpackChunkName: "component---content-es-build-outsmarting-contracts-true-store-mdx" */),
  "component---content-es-learn-module-0-conversation-mdx": () => import("./../../../content/es/learn/module-0/conversation.mdx" /* webpackChunkName: "component---content-es-learn-module-0-conversation-mdx" */),
  "component---content-es-learn-module-0-index-mdx": () => import("./../../../content/es/learn/module-0/index.mdx" /* webpackChunkName: "component---content-es-learn-module-0-index-mdx" */),
  "component---content-es-learn-module-0-money-language-mdx": () => import("./../../../content/es/learn/module-0/money-language.mdx" /* webpackChunkName: "component---content-es-learn-module-0-money-language-mdx" */),
  "component---content-es-learn-module-0-play-of-pattern-mdx": () => import("./../../../content/es/learn/module-0/play-of-pattern.mdx" /* webpackChunkName: "component---content-es-learn-module-0-play-of-pattern-mdx" */),
  "component---content-es-learn-module-0-purpose-mdx": () => import("./../../../content/es/learn/module-0/purpose.mdx" /* webpackChunkName: "component---content-es-learn-module-0-purpose-mdx" */),
  "component---content-es-learn-module-0-trust-mdx": () => import("./../../../content/es/learn/module-0/trust.mdx" /* webpackChunkName: "component---content-es-learn-module-0-trust-mdx" */),
  "component---content-es-learn-module-1-dreamers-mdx": () => import("./../../../content/es/learn/module-1/dreamers.mdx" /* webpackChunkName: "component---content-es-learn-module-1-dreamers-mdx" */),
  "component---content-es-learn-module-1-index-mdx": () => import("./../../../content/es/learn/module-1/index.mdx" /* webpackChunkName: "component---content-es-learn-module-1-index-mdx" */),
  "component---content-es-learn-module-1-joyful-subversion-mdx": () => import("./../../../content/es/learn/module-1/joyful-subversion.mdx" /* webpackChunkName: "component---content-es-learn-module-1-joyful-subversion-mdx" */),
  "component---content-es-learn-module-1-meaning-mdx": () => import("./../../../content/es/learn/module-1/meaning.mdx" /* webpackChunkName: "component---content-es-learn-module-1-meaning-mdx" */),
  "component---content-es-learn-module-1-playdough-protocols-mdx": () => import("./../../../content/es/learn/module-1/playdough-protocols.mdx" /* webpackChunkName: "component---content-es-learn-module-1-playdough-protocols-mdx" */),
  "component---content-es-learn-module-1-understanding-ethereum-mdx": () => import("./../../../content/es/learn/module-1/understanding-ethereum.mdx" /* webpackChunkName: "component---content-es-learn-module-1-understanding-ethereum-mdx" */),
  "component---content-es-learn-module-1-value-mdx": () => import("./../../../content/es/learn/module-1/value.mdx" /* webpackChunkName: "component---content-es-learn-module-1-value-mdx" */),
  "component---content-es-learn-module-2-better-questions-mdx": () => import("./../../../content/es/learn/module-2/better-questions.mdx" /* webpackChunkName: "component---content-es-learn-module-2-better-questions-mdx" */),
  "component---content-fr-index-mdx": () => import("./../../../content/fr/index.mdx" /* webpackChunkName: "component---content-fr-index-mdx" */),
  "component---content-fr-resources-culture-mdx": () => import("./../../../content/fr/resources/culture.mdx" /* webpackChunkName: "component---content-fr-resources-culture-mdx" */),
  "component---content-fr-resources-design-ux-mdx": () => import("./../../../content/fr/resources/design-ux.mdx" /* webpackChunkName: "component---content-fr-resources-design-ux-mdx" */),
  "component---content-fr-resources-development-mdx": () => import("./../../../content/fr/resources/development.mdx" /* webpackChunkName: "component---content-fr-resources-development-mdx" */),
  "component---content-fr-resources-index-mdx": () => import("./../../../content/fr/resources/index.mdx" /* webpackChunkName: "component---content-fr-resources-index-mdx" */),
  "component---content-fr-resources-research-mdx": () => import("./../../../content/fr/resources/research.mdx" /* webpackChunkName: "component---content-fr-resources-research-mdx" */),
  "component---content-fr-resources-security-mdx": () => import("./../../../content/fr/resources/security.mdx" /* webpackChunkName: "component---content-fr-resources-security-mdx" */),
  "component---content-fr-start-faq-mdx": () => import("./../../../content/fr/start/faq.mdx" /* webpackChunkName: "component---content-fr-start-faq-mdx" */),
  "component---content-fr-start-free-learning-mdx": () => import("./../../../content/fr/start/free-learning.mdx" /* webpackChunkName: "component---content-fr-start-free-learning-mdx" */),
  "component---content-fr-start-how-to-use-mdx": () => import("./../../../content/fr/start/how-to-use.mdx" /* webpackChunkName: "component---content-fr-start-how-to-use-mdx" */),
  "component---content-fr-start-index-mdx": () => import("./../../../content/fr/start/index.mdx" /* webpackChunkName: "component---content-fr-start-index-mdx" */),
  "component---content-fr-start-principled-people-mdx": () => import("./../../../content/fr/start/principled-people.mdx" /* webpackChunkName: "component---content-fr-start-principled-people-mdx" */),
  "component---content-pr-learn-index-mdx": () => import("./../../../content/pr/learn/index.mdx" /* webpackChunkName: "component---content-pr-learn-index-mdx" */),
  "component---content-pr-learn-module-0-conversation-mdx": () => import("./../../../content/pr/learn/module-0/conversation.mdx" /* webpackChunkName: "component---content-pr-learn-module-0-conversation-mdx" */),
  "component---content-pr-learn-module-0-index-mdx": () => import("./../../../content/pr/learn/module-0/index.mdx" /* webpackChunkName: "component---content-pr-learn-module-0-index-mdx" */),
  "component---content-pr-learn-module-0-money-language-mdx": () => import("./../../../content/pr/learn/module-0/money-language.mdx" /* webpackChunkName: "component---content-pr-learn-module-0-money-language-mdx" */),
  "component---content-pr-learn-module-0-play-of-pattern-mdx": () => import("./../../../content/pr/learn/module-0/play-of-pattern.mdx" /* webpackChunkName: "component---content-pr-learn-module-0-play-of-pattern-mdx" */),
  "component---content-pr-learn-module-0-purpose-mdx": () => import("./../../../content/pr/learn/module-0/purpose.mdx" /* webpackChunkName: "component---content-pr-learn-module-0-purpose-mdx" */),
  "component---content-pr-learn-module-0-trust-mdx": () => import("./../../../content/pr/learn/module-0/trust.mdx" /* webpackChunkName: "component---content-pr-learn-module-0-trust-mdx" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-en-blog-js": () => import("./../../../src/pages/en/blog.js" /* webpackChunkName: "component---src-pages-en-blog-js" */),
  "component---src-pages-en-learn-module-2-money-speech-js": () => import("./../../../src/pages/en/learn/module-2/money-speech.js" /* webpackChunkName: "component---src-pages-en-learn-module-2-money-speech-js" */),
  "component---src-pages-en-love-js": () => import("./../../../src/pages/en/love.js" /* webpackChunkName: "component---src-pages-en-love-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

